import React, { useState, useEffect } from 'react';
import './ImageCarousel.css';

import logo1 from './carrucel/1.png';
import logo2 from './carrucel/2.png';
import logo3 from './carrucel/3.png';
import logo4 from './carrucel/4.png';
import logo5 from './carrucel/5.png';

const logos = [
  { src: logo1, alt: 'Logo 1' },
  { src: logo2, alt: 'Logo 2' },
  { src: logo3, alt: 'Logo 3' },
  { src: logo4, alt: 'Logo 4' },
  { src: logo5, alt: 'Logo 5' },
];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextLogo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
  };

  const prevLogo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + logos.length) % logos.length);
  };

  useEffect(() => {
    const interval = setInterval(nextLogo, 3000); // Cambia cada 3 segundos
    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, []);

  return (
    <div className="carousel-container">
      <h2>Nuestros Clientes</h2>
      <div className="carousel">
        <button className="carousel-button left" onClick={prevLogo}>❮</button>
        <div className="carousel-logos">
          <img src={logos[currentIndex].src} alt={logos[currentIndex].alt} className="carousel-logo" />
        </div>
        <button className="carousel-button right" onClick={nextLogo}>❯</button>
      </div>
    </div>
  );
};

export default ImageCarousel;