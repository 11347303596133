import React from 'react';
import './FullScreenModal.css'; // Asegúrate de crear este archivo CSS
import { useEffect } from 'react';

const FullScreenModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Si el modal no está abierto, no renderizar nada
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2 className="promo-title">¡Promoción de Fin de Año!</h2>
        <p className="promo-description">
          Páginas web a tan solo <strong>$80</strong>, dominio y hosting por un año.
        </p>
        <p className="promo-description">
          ¡No puedes perder esta oportunidad!
        </p>
      </div>
    </div>
  );
};

export default FullScreenModal;