import React, { useState } from 'react';
import './WhatsApp.css'; // Asegúrate de crear este archivo CSS

const WhatsApp = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (message) => {
    console.log(`Botón clicado: ${message}`); // Línea de depuración
    const phoneNumber = '+573007579665'; // Reemplaza con el número de WhatsApp
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-container">
      <button className="whatsapp-button" onClick={handleToggle}>
        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
      </button>
      {isOpen && (
        <div className="whatsapp-options">
          <button onClick={() => handleOptionClick('Quiero información sobre página web')}>Información sobre página web</button>
          <button onClick={() => handleOptionClick('Quiero información sobre plataforma escolar')}>Información sobre plataforma escolar</button>
          <button onClick={() => handleOptionClick('Quiero información sobre aplicaciones empresariales')}>Información sobre aplicaciones empresariales</button>
          <button onClick={() => handleOptionClick('Tengo una pregunta')}>Tengo una pregunta</button>
        </div>
      )}
    </div>
  );
};

export default WhatsApp;