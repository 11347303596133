import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './styles.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Obtener valores de los campos del formulario
    const formData = new FormData(form.current);
    const userEmail = formData.get('user_email'); // Obtener el email
    const userName = formData.get('user_name'); // Obtener el nombre
    const userPhone = formData.get('user_phone'); // Obtener el teléfono
    const message = formData.get('message'); // Obtener el mensaje

    // Crear un cuerpo de mensaje personalizado
    const emailBody = `
      Nombre: ${userName}
      Email: ${userEmail}
      Teléfono: ${userPhone}
      Mensaje: ${message}
    `;

    emailjs.send('audinpadron', 'template_1mm6f8s', {
      user_name: userName,
      user_email: userEmail,
      user_phone: userPhone,
      message: emailBody,
    }, '52B68XoESNxpCUrgj')
      .then((result) => {
          console.log(result.text);
          alert("Mensaje enviado con éxito!");
      }, (error) => {
          console.log(error.text);
          alert("Error al enviar el mensaje. Inténtalo de nuevo.");
      });
  };

  return (
    <section id="contact" className="contact">
      <h2>Contáctanos</h2>
      <form ref={form} className="contact-form" onSubmit={sendEmail}>
        <input type="text" name="user_name" placeholder="Nombre" required />
        <input type="email" name="user_email" placeholder="Email" required />
        <input type="tel" name="user_phone" placeholder="Teléfono" required />
        <textarea name="message" placeholder="Mensaje" required></textarea>
        <button type="submit">Enviar</button>
      </form>
    </section>
  );
};

export default Contact;