import React from 'react';
import './styles.css';

const Services = () => {
  return (
    <section id="services" className="services">
      <h2>Nuestros Servicios</h2>
      <div className="service-cards">
        <div className="service-card">
          <h3>Plataformas Educativas</h3>
          <p>
            **Learnin Estudens**: Nuestra plataforma educativa ofrece un entorno de aprendizaje interactivo y accesible. 
            Con seguimiento del progreso y una amplia biblioteca de recursos, facilitamos el aprendizaje personalizado y la colaboración entre estudiantes y profesores.
          </p>
        </div>
        <div className="service-card">
          <h3>Páginas Web</h3>
          <p>
            Creamos **páginas web** atractivas y funcionales. Solo pagas el hosting y el dominio, lo que permite a pequeñas empresas tener presencia en línea sin altos costos iniciales. 
            Incluimos optimización SEO y diseño adaptable para dispositivos móviles.
          </p>
        </div>
        <div className="service-card">
          <h3>Aplicaciones Web</h3>
          <p>
            Nos especializamos en **aplicaciones web** personalizadas para negocios de todos los tamaños. 
            Ofrecemos soluciones escalables que mejoran la eficiencia operativa y la interacción con los clientes, adaptándonos a tus necesidades específicas.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;