import React from 'react';
import './TeamProfiles.css'; // Asegúrate de crear este archivo CSS
import yulyImage from './images/1.png'; // Imagen de Yuly
import audinImage from './images/2.png'; // Imagen de Audin

const teamMembers = [
  {
    name: 'Yuly de Alba',
    role: 'Ventas',
    bio: 'Comprometida con su trabajo, se esfuerza por encontrar el mejor camino para nuestros clientes. Cuenta con más de 16 años en el sector tecnológico y telecomunicaciones.',
    image: yulyImage // Reemplaza con la URL de la imagen de Yuly
  },
  {
    name: 'Audin Padron',
    role: 'Desarrollador',
    bio: 'Especialista en PHP y Node.js con más de 7 años de experiencia en el desarrollo, acompañando a muchas PYMES a desarrollar sus ideas.',
    image: audinImage // Reemplaza con la URL de la imagen de Audin
  }
];

const TeamProfiles = () => {
  return (
    <div className="team-profiles">
      <h2 className="team-title">Equipo</h2> {/* Añade una clase para el título */}
      <div className="profiles-container"> {/* Contenedor para los perfiles */}
        {teamMembers.map((member, index) => (
          <div className="profile-card" key={index}>
            <img src={member.image} alt={member.name} className="profile-image" />
            <div className="profile-info">
              <h3>{member.name}</h3>
              <p>{member.role}</p>
              <div className="bio">{member.bio}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TeamProfiles;