import React from 'react';
import './styles.css';

const Hero = () => {
  return (
    <section className="hero">
      <h1>Desarrollamos Tus Sueños</h1>
      <p>Creamos aplicaciones web para todo tipo de negocio.</p>
      <a href="#contact" className="cta-button">Contáctanos</a>
    </section>
  );
};

export default Hero;
