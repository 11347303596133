import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import TeamProfiles from './components/TeamProfiles';
import ImageCarousel from './components/ImageCarousel';
import WhatsApp from './components/WhatsApp'; // Importa el nuevo componente de WhatsApp
import FullScreenModal from './components/FullScreenModal'; // Importa el nuevo componente de Modal
import './App.css';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);

  // Efecto para abrir el modal al cargar la página
  useEffect(() => {
    setModalOpen(true);
  }, []);

  const closeModal = () => setModalOpen(false);

  return (
    <div className="App">
      <Header />
      <Hero />
      <Services />
      <ImageCarousel />
      <TeamProfiles />
      <Testimonials />
      <Contact />
      <Footer />
      <WhatsApp /> {/* Añade el componente de WhatsApp aquí */}
      <FullScreenModal isOpen={isModalOpen} onClose={closeModal} /> {/* Modal */}
    </div>
  );
}

export default App;