import React from 'react';
import { useSpring, animated } from 'react-spring';
import './styles.css';

const Header = () => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 1000 } });

  return (
    <animated.header className="header" style={props}>
      <div className="logo">Audin Padron Developer</div>
      <nav className="nav">
        <a href="#services">Servicios</a>
        <a href="#testimonials">Testimonios</a>
        <a href="#contact">Contacto</a>
      </nav>
    </animated.header>
  );
};

export default Header;