import React from 'react';
import './styles.css';

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials">
      <h2>Testimonios</h2>
      <div className="testimonial-cards">
        <div className="testimonial-card">
          <p>"Excelente servicio, muy profesionales."</p>
          <span>- Cliente Satisfecho</span>
        </div>
        <div className="testimonial-card">
          <p>"Mi negocio ha crecido gracias a su trabajo."</p>
          <span>- Empresario</span>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
